








































































  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
  import { ServerResponse } from '@/services/response.types';
  import { ContentModel, FAQGroupModel, FAQItemsModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action, State } from 'vuex-class';

  interface TabButtonIterface {
    desc: string;
    title: string;
    variant: string;
  }

  interface TABInterface {
    title: string;
    desc: string[];
    button?: TabButtonIterface;
  }

  interface FAQItem {
    title: string;
    desc: string[];
    img?: string;
    tab?: boolean;
    tabList?: TABInterface[];
  }

  interface ListFAQ {
    title: string;
    item: FAQItem[];
  }

  @Component({
    name: 'FAQ',
    components: {
      protectNow: () => import('@/components/ProtectNow.vue')
    },
    mixins: [],
  })
  export default class FAQ extends Vue {
    @Action('GetFaqGroup') GetFaqGroup!: () => Promise<ServerResponse>;
    @Action('GetFaqItems') GetFaqItems!: () => Promise<ServerResponse>;

    @State('page_content') page_content!: ContentModel[];
    @State('faq_group') faq_group!: FAQGroupModel[];
    @State('faq_item') faq_item!: FAQItemsModel[];

    public get banner(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.FAQ)[0]?.image ?? require('@/assets/images/banner/partner.jpg');
    }

    public search = '';


    public get listFAQ() : ListFAQ[] {
      return this.faq_group.map((group) => {

        let items:FAQItem[] = this.faq_item.filter((item)=>item.group_id === group.group_id).map((item) => {
          return {
            title: item.title_ind,
            desc: [item.content_ind],
          }
        })

        return {
          title: group.title_ind,
          item: items
        }
      })
    }

    public created(): void {
      this.GetFaqGroup();
      this.GetFaqItems();
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
